export default {
  HERO: {
    title: 'About',
    description:
      'SolClout is envisioning a platform to build a formidable community powered by Solana, a high throughput, scalable public blockchain project. SolClout will allow for cryptocurrency projects and users to create and boot-strap Clout-driven communities geared towards building fast and sustainable social presences.',
  },
  ABOUT: [
    {
      title: 'DeFi meets Social ',
      description:
        'SolClout Starter is a messenger-based IDO platform. Social networking based IDO platform, SolClout Starter allows for projects to establish official communities. Users with best activity could receive an allocation for the initial decentralized offering.',
      image: 'governance',
    },
    {
      title: 'Blockchain-powered Social Networking',
      description:
        'SolClout Social is a simple blockchain-based social networking feature embedded within the application. Users can enjoy a seamless user experience. Powered by blockchain technology, users are able to also utilize a social networking platform free from censorship.',
      image: 'feed',
    },
  ],
  TABLE: [
    ['List of features', 'Alpha', 'MVP', 'Full App', 'Viber', 'Telegram', 'Whatsapp', 'Messenger'],
    ['Login with a secret key', true, true, true, false, false, false, false],
    ['Account Generation: Address + Bio + Avatar', true, true, true, false, false, false, false],
    ['Create groups', false, true, true, true, true, true, true],
    ['Audio Calls', false, false, false, true, true, true, true],
    ['Video Calls', false, false, false, true, false, true, true],
    ['Stickers', true, true, true, true, true, true, true],
    ['Custom Stickers', true, true, true, true, true, true, true],
    ['Channels + Folder for them', false, true, true, true, true, false, true],
    ['Favourites', false, false, true, true, true, true, true],
    ['Chats archives', false, false, true, true, true, true, true],
    ['Admin for groups', false, true, true, true, true, true, true],
    ['Calendar', false, false, true, false, true, false, false],
    ['Build in browser', false, false, true, false, true, false, true],
    ['Stories', false, false, true, false, false, true, false],
    ['Delete message for both', false, false, true, false, true, false, false],
    ['Share current location', false, false, true, true, true, true, false],
    ['Current location in real time', false, false, true, false, true, true, false],
    ['Black list', false, false, true, true, true, true, true],
    ['Custom Settings Confidentiality', false, false, false, true, true, true, true],
    ['Secret Chat', false, false, true, true, true, true, true],
    ['Comments', false, false, true, false, true, false, false],
    ['QR code scan / share', false, false, false, true, false, true, false],
    ['Emoji reaction', false, true, true, false, true, false, true],
  ],
}
