import * as React from 'react'
import Layout from '~components/Layout'
import SEO from '~components/seo'
import Hero from '~components/Hero'
import { Table } from '~components/Table'
import { AboutBlock } from './components/AboutBlock'

import DATA from './data'

export const About = () => (
  <Layout>
    <SEO title={DATA.HERO.title} />
    <Hero {...DATA.HERO} modifier="about" />
    {DATA.ABOUT.map((item, index) => (
      <AboutBlock key={item.title} {...item} isFirst={index === 0} />
    ))}
    <Table data={DATA.TABLE} isContentImages selectedColumns={[2, 3, 4]} modifier="about" />
  </Layout>
)
