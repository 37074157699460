import * as React from 'react'
import cn from 'classnames'

import { Container } from 'react-bootstrap'

import * as s from './style.module.scss'

import sprite from '~img/icons.svg'

const ImageCeil = ({ item, isSelected, modifier }) => (
  <td className={cn(s[modifier], { [s.selected]: isSelected })}>
    {item && <span className={cn(s.checkMark)} arial-label="Check mark"></span>}
  </td>
)

const TextCeil = ({ item, isSelected, modifier }) => (
  <td className={cn(s[modifier], { [s.selected]: isSelected })}>
    {Array.isArray(item)
      ? item.map((el) => (
          <>
            {el}
            <br />
          </>
        ))
      : item}
  </td>
)

export const Table = ({ data, selectedColumns, isContentImages, modifier }) => (
  <Container as="section" className={cn(s.table, s[modifier])}>
    <div className={(s.table, s.table__wrap)}>
      <table cols={8} className={s[modifier]}>
        <thead>
          <tr>
            {data[0].map((item, index) => (
              <th className={cn(s[modifier], { [s.selected]: selectedColumns.includes(index + 1) })} key={item}>
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data
            .filter((_, index) => index)
            .map((row) => (
              <tr key={row[0]}>
                {row.map((item, index) =>
                  index && isContentImages ? (
                    <ImageCeil
                      modifier={modifier}
                      key={row[0] + index}
                      item={item}
                      isSelected={selectedColumns.includes(index + 1)}
                    />
                  ) : (
                    <TextCeil
                      modifier={modifier}
                      item={item}
                      isSelected={selectedColumns.includes(index + 1)}
                      key={row[0] + index}
                    />
                  )
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  </Container>
)
