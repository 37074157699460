import * as React from 'react'
import cn from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { Container } from 'react-bootstrap'

import * as s from './style.module.scss'

export const AboutBlock = ({ title, description, image, isFirst = false }) => {
  const data = useStaticQuery(graphql`
    {
      allImageSharp {
        edges {
          node {
            fixed {
              originalName
            }
            gatsbyImageData(layout: CONSTRAINED, width: 279, height: 550, placeholder: TRACED_SVG)
          }
        }
      }
    }
  `)

  const imageNode = data.allImageSharp.edges.find(({ node: { fixed: { originalName } } }) =>
    originalName.includes(image)
  )

  return (
    <Container as="section" className={cn(s.about, { [s.top]: isFirst })}>
      <div className={s.about__descr}>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <div className={cn(s.about__img, { [s.rtl]: isFirst })}>
        <GatsbyImage width={300} height={600} image={imageNode.node.gatsbyImageData} alt={image} />
      </div>
    </Container>
  )
}
